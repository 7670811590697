import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import { PageContainer, ContentWrapper, NotFoundMessage } from '../components/styles/404.styled';
import { SubscribeButton, SubscribeButtonWrapper } from '../components/styles/Footer.styled';
// import Header from '../components/Header';
// import Footer from '../components/Footer';

export default function NotFound() {
  const query = useStaticQuery(graphql`
    {
      umbraco {
        allHospitalitySettings {
          items {
            contentTypeAlias
            copyrightText
            facebook
            footerText
            mainNavigation {
              content {
                ... on Umbraco_NavigationItem {
                  link {
                    name
                    url
                  }
                }
              }
            }
            links {
              name
              url
            }
            subscribeLink {
              name
              url
            }
            instagram
            makeAnEnquiryLink {
              name
              url
            }
            bottomLinks {
              name
              url
            }
            tikTok
            whatsApp
            youtube
            url
            twitter
            images {
              url
            }
            footerMenu {
              content {
                ... on Umbraco_LinkGroup {
                  urls {
                    name
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  // console.log(query);
  const settings = query.umbraco.allHospitalitySettings.items[0];
  console.log(settings);

  return (
    <Layout>
      {/* <Header settings={settings} currentPage={{ url: '/en/' }}/> */}
      <PageContainer>
        <ContentWrapper>
          <NotFoundMessage>Sorry, we can't find the page you are looking for.</NotFoundMessage>
          <NotFoundMessage className="arabic_message">
            عذراً، لم نتمكّن من العثور على ما تبحث عنه
          </NotFoundMessage>
          <SubscribeButtonWrapper className="notfound_button">
            <SubscribeButton to="/">Home</SubscribeButton>
          </SubscribeButtonWrapper>
        </ContentWrapper>
      </PageContainer>
    </Layout>
  );
}
